import moment from "moment"
import { toast } from "react-toastify"
import SuccessToast from "./componentsToast/SucessToast"
import axios from "axios"
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getTipoUser = () => {
  const item = JSON.parse(localStorage.getItem('userData'))
  const tipo = item?.autenticacao.tipo.toLowerCase()
  return typeof tipo !== 'undefined' ? tipo : null
}
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})


export const getDaysDiff = (start_date, end_date, date_format = 'DD/MM/YYYY') => {
  const getDateAsArray = (date) => {
    return moment(date.split(/\D+/), date_format)
  }
  return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days')
}

export function capitalizeFirstLetter(string) {
  if (!string) return ''
  if (string.split(" ").length >= 2) {
    const ArrayString = []
    const Array = string.split(' ')
    for (let index = 0; index < Array.length; index++) {
      const element = Array[index].toLowerCase()
      ArrayString.push(element.charAt(0).toUpperCase() + element.slice(1))
    }
    return ArrayString.join(" ")
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getMonth() {
  const date = new Date()
  return capitalizeFirstLetter(moment(date).format('MMMM'))
}

export function getCookie(name) {
  const value = `; ${document.cookie}`

  console.log(value)
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const estados = [
  { name: 'Escolha o estado', nome: '0' },
  { name: 'AL', nome: 'Alagoas' },
  { name: 'AC', nome: 'Acre' },
  { name: 'AP', nome: 'Amapá' },
  { name: 'AM', nome: 'Amazonas' },
  { name: 'BA', nome: 'Bahia' },
  { name: 'CE', nome: 'Ceará' },
  { name: 'DF', nome: 'Distrito Federal' },
  { name: 'ES', nome: 'Espírito Santo' },
  { name: 'GO', nome: 'Goiás' },
  { name: 'MA', nome: 'Maranhão' },
  { name: 'MS', nome: 'Mato Grosso do Sul' },
  { name: 'MT', nome: 'Mato Grosso' },
  { name: 'MG', nome: 'Minas Gerais' },
  { name: 'PA', nome: 'Pará' },
  { name: 'PB', nome: 'Paraíba' },
  { name: 'PR', nome: 'Paraná' },
  { name: 'PE', nome: 'Pernambuco' },
  { name: 'PI', nome: 'Piauí' },
  { name: 'RJ', nome: 'Rio de Janeiro' },
  { name: 'RN', nome: 'Rio Grande do Norte' },
  { name: 'RS', nome: 'Rio Grande do Sul' },
  { name: 'RO', nome: 'Rondônia' },
  { name: 'RR', nome: 'Roraima' },
  { name: 'SC', nome: 'Santa Catarina' },
  { name: 'SP', nome: 'São Paulo' },
  { name: 'SE', nome: 'Sergipe' },
  { name: 'TO', nome: 'Tocantins' }
]


const segmentos = [
  { name: 'Escolha o segmento', name: '0' },
  { name: 'Escolha o segmento', name: '0' }
]
export function numberIsValid(value) {
  const regex = /(\d)\1{6}/g //verifica se há repetição
  let numeroLimpo = value.replace(/\s+/g, '') // retira espaços vazios
  numeroLimpo = typeof (numeroLimpo.split(')')[1]) === 'undefined' ? numeroLimpo.split(' ').shift() : numeroLimpo.split(')')[1]
  numeroLimpo = numeroLimpo.replace(/[^0-9]/g, '')  // retira caracter
  return regex.test(numeroLimpo)
}

export function maskTelefone(e) {
  let value = e.target.value
  value = value.replace(/\D/g, "")      //Remove tudo o que não é dígito
  value = value.replace(/(\d)(\d{3})$/, "$1-$2")
  //Coloca hífen entre o quarto e o quinto dígitos

  e.target.value = value
  return e
}

export function hasAcessSieg() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const access = item?.user.acesso_sieg || item.user?.automacao_modulos?.includes('sieg')
  return typeof access !== 'undefined' ? access : null
}
export function hasParceria() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const access = item?.user.parceria
  return typeof access !== 'undefined' ? access : null
}

export function getTipoAutomacaoComercial() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const automacao = item?.user.plano.tipo
  return automacao === 'COMERCIAL'
}
export function getTipoPlano() {
  const item = JSON.parse(localStorage.getItem('userData'))
  const automacao = item?.user.plano.tipo
  return automacao
}
export const anos = [
  { ano: 2024 },
  { ano: 2023 },
  { ano: 2022 },
  { ano: 2021 },
  { ano: 2020 },
  { ano: 2019 },
  { ano: 2018 }
]

export function formatNCM(ncm) {
  if (!ncm) return ''
  if (ncm && ncm.length >= 10) return ncm
  return ncm?.replace(/(\d{4})(\d{2})(\d{2})/, "\$1.\$2.\$3")
}
export function formatCEST(cest) {
  if (!cest) return ''
  return cest.replace(/(\d{2})(\d{3})(\d{2})/, "\$1.\$2.\$3")
}

export function copyToClipboard(key, msg) {
  navigator.clipboard.writeText(key)
  toast(<SuccessToast message={msg} />)
}

export function formatCurrencyBRL(number, digits = 2) {
  if (!number && number !== 0) {
    return "R$ 0"
  }
  return parseFloat(number).toLocaleString("pt-br", { minimumFractionDigits: digits, style: "currency", currency: "BRL" })
}

export function formatPorcent(number) {
  if (!number && number !== 0) {
    return "0"
  }
  return parseFloat(number).toFixed(2).replace('.', ',')
}

export const ambientes = {
  0: "ICMS-ST",
  1: "DIFAL",
  2: "NÃO - TRIBUTADO",
  3: "ZERADO",
  4: "ST - DIFAL",
  5: "BASE DUPLA",
  6: "363 - A - I",
  7: "363 - A - II",
  8: "363 - A - III",
  9: "ANTARTIGO I",
  10: "ANTARTIGO III D",
  11: "ANTARTIGO IIII F",
  12: "ANTECIPAÇÃO IMPORTADOS"
}

export function formatDateUStoBR(date) {
  if (!date) return null
  return date.replaceAll('-', '/').split('/').reverse().join('/')
}
export function formatDateBRtoUS(date, pattern = '-') {
  if (!date) return ''
  return date.split('/').reverse().join(pattern)
}

export function formatDateBR(date) {
  if (!date) return null

  return moment(date).format('DD/MM/YYYY')
}
export function CnpjWithoutDots(value) {
  if (value.length !== 0) {
    return value.replace(/[^\d]+/g, "")
  }
  return value
}

export const unidades = [
  { Unidade: 'AMPOLA', descricao: 'AMPOLA' },
  { Unidade: 'BALDE', descricao: 'BALDE' },
  { Unidade: 'BANDEJ', descricao: 'BANDEJA' },
  { Unidade: 'BARRA', descricao: 'BARRA' },
  { Unidade: 'BISNAG', descricao: 'BISNAGA' },
  { Unidade: 'BLOCO', descricao: 'BLOCO' },
  { Unidade: 'BOBINA', descricao: 'BOBINA' },
  { Unidade: 'BOMB', descricao: 'BOMBONA' },
  { Unidade: 'CAPS', descricao: 'CAPSULA' },
  { Unidade: 'CART', descricao: 'CARTELA' },
  { Unidade: 'CENTO', descricao: 'CENTO' },
  { Unidade: 'CJ', descricao: 'CONJUNTO' },
  { Unidade: 'CM', descricao: 'CENTIMETRO' },
  { Unidade: 'CM2', descricao: 'CENTIMETRO QUADRADO' },
  { Unidade: 'CX', descricao: 'CAIXA' },
  { Unidade: 'DISP', descricao: 'DISPLAY' },
  { Unidade: 'DUZIA', descricao: 'DUZIA' },
  { Unidade: 'EMBAL', descricao: 'EMBALAGEM' },
  { Unidade: 'FARDO', descricao: 'FARDO' },
  { Unidade: 'FOLHA', descricao: 'FOLHA' },
  { Unidade: 'FRASCO', descricao: 'FRASCO' },
  { Unidade: 'GALAO', descricao: 'GALÃO' },
  { Unidade: 'GF', descricao: 'GARRAFA' },
  { Unidade: 'GRAMAS', descricao: 'GRAMAS' },
  { Unidade: 'JOGO', descricao: 'JOGO' },
  { Unidade: 'KG', descricao: 'QUILOGRAMA' },
  { Unidade: 'KIT', descricao: 'KIT' },
  { Unidade: 'LATA', descricao: 'LATA' },
  { Unidade: 'LITRO', descricao: 'LITRO' },
  { Unidade: 'M', descricao: 'METRO' },
  { Unidade: 'M2', descricao: 'METRO QUADRADO' },
  { Unidade: 'M3', descricao: 'METRO CÚBICO' },
  { Unidade: 'MILHEI', descricao: 'MILHEIRO' },
  { Unidade: 'ML', descricao: 'MILILITRO' },
  { Unidade: 'MWH', descricao: 'MEGAWATT HORA' },
  { Unidade: 'PACOTE', descricao: 'PACOTE' },
  { Unidade: 'PALETE', descricao: 'PALETE' },
  { Unidade: 'PARES', descricao: 'PARES' },
  { Unidade: 'PC', descricao: 'PEÇA' },
  { Unidade: 'POTE', descricao: 'POTE' },
  { Unidade: 'K', descricao: 'QUILATE' },
  { Unidade: 'RESMA', descricao: 'RESMA' },
  { Unidade: 'ROLO', descricao: 'ROLO' },
  { Unidade: 'SACO', descricao: 'SACO' },
  { Unidade: 'SACOLA', descricao: 'SACOLA' },
  { Unidade: 'TAMBOR', descricao: 'TAMBOR' },
  { Unidade: 'TANQUE', descricao: 'TANQUE' },
  { Unidade: 'TON', descricao: 'TONELADA' },
  { Unidade: 'TUBO', descricao: 'TUBO' },
  { Unidade: 'UNID', descricao: 'UNIDADE' },
  { Unidade: 'VASIL', descricao: 'VASILHAME' },
  { Unidade: 'VIDRO', descricao: 'VIDRO' }
]

export function formatCEP(value) {
  if (!value) return ''

  return value.replace(/^(\d{5})(\d{3})$/, "$1-$2")
}

export const status_produtos = [
  {
    "status": "Sem NCM",
    "key": "ncm"
  },
  {
    "status": "Sem CEST",
    "key": "cest"
  },
  {
    "status": "Sem EAN",
    "key": "ean"
  },
  {
    "status": "Sem categoria",
    "key": "categoria"
  },
  {
    "status": "Sem marca",
    "key": "marca"
  },
  {
    "status": "Sem descrição",
    "key": "descricao"
  },
  {
    "status": "Sem unidade",
    "key": "unidade"
  },
  {
    "status": "Sem fabricante ou importador",
    "key": "origem"
  },
  {
    "status": "CEST divergente ou inválido",
    "key": "cest divergente ou inválido"
  },
  {
    "status": "NCM inválido",
    "key": "ncm inválido"
  }
]


export const bancos = [
  { codigo: '0', name: 'Escolha o seu banco' },
  { codigo: '001', name: ' Banco do Brasil' },
  { codigo: '004', name: ' Banco do Nordeste do Brasil' },
  { codigo: '033', name: ' Banco Santander' },
  { codigo: '041', name: ' Banco Banrisul' },
  { codigo: '104', name: ' Caixa Econônima Federal' },
  { codigo: '136', name: ' Banco Unicred' },
  { codigo: '237', name: ' Banco Bradesco' },
  { codigo: '318', name: ' Banco BMG' },
  { codigo: '341', name: ' Banco Itaú' },
  { codigo: '422', name: ' Banco Safra' },
  { codigo: '745', name: ' Banco Citibank' },
  { codigo: '748', name: ' Banco Sicredi' },
  { codigo: '756', name: ' Banco Sicoob' },
  { codigo: '336', name: ' Banco C6 bank' }
]
const getData = new Date()

export const CurrentMonth = getData.getMonth() + 1

export const notificao_estudual = ['RO', 'ES', 'SP']

export const certidao_municipal_estados = ['GO', 'SP', 'RS', 'MG', 'SC', 'MS', 'RO', 'ES', 'AM', 'AC']

export const FOB_ESTADOS = ["mg", "pb", "ms", 'pi', 'am', 'ba', 'al', 'mt']

export const estadosIE = ['mg', 'df', 'to', 'mt']

export async function handleCountStates(array) {
  const estadosObj = []
  await new Promise((resolve) => {
    if (!Array.isArray(array) && array.length > 0) {
      console.error('Erro: A entrada precisa ser um array.')
      return []
    }

    const stateCounts = array.reduce((counts, element) => {
      counts[element] = (counts[element] || 0) + 1
      return counts
    }, {})

    for (const [state, count] of Object.entries(stateCounts)) {
      estadosObj.push({ state: state, count: count })
    }
    resolve()
  })
  return estadosObj
}

export const modules = {
  'cnd': 'cnd',
  'ecac': 'ecac',
  "cadastroEmpresa": "empresa",
  'calendario': 'calendario',
  'cadastro': 'cadastro',
  'monitoramento': 'monitoramento',
  'captura-xml': 'captura-xml',
  'validador-compra': 'validador-compra',
  'guias-compra': 'guias-compra',
  'pedido-compra': 'pedido-compra',
  'fornecedores': 'fornecedores',
  'insights-compra': 'insights-compra',
  'validador-saida': 'validador-saida',
  'guias-saida': 'guias-saida',
  'pedido-saida': 'pedido-saida',
  'simuladorNovo': 'simulador_novo',
  'clientes': 'clientes',
  'insights': 'insights',
  'api': 'api',
  'escrituracao': 'escrituracao',
  'consultas-mva': 'consultas-mva',
  "produtos-cadastro": 'produtos-cadastro',
  'pagamentos': 'pagamentos',
  'simuladorLegado': 'simuladorLegado',
  'produtos': 'produtos'
}

export const modulos = [
  {
    "title": "Empresas",

    "sub_modulos": [
      { sub_modulo: 'CND e e-CAC/SEFAZ', value: "cnd", "tooltiptext": 'Selecione esta opção para atribuir esta funcionalidade ao contrato.', dependsOn: ['empresa'] },
      { sub_modulo: 'Agenda de obrigações', value: 'calendario', "tooltiptext": 'Selecione esta opção para atribuir esta funcionalidade ao contrato.' },
      { sub_modulo: 'Empresa', value: 'empresa', "tooltiptext": 'Selecione esta opção para atribuir esta funcionalidade ao contrato.' }
    ]
  },
  {
    "title": "Produtos",
    "sub_modulos": [
      {
        sub_modulo: 'Monitoramento', value: "monitoramento", "tooltiptext": 'Selecione esta opção para habilitar a função de monitoramento de regras tributárias dos produtos cadastrados.', dependsOn: ['produtos', 'empresa']
      },
      {
        sub_modulo: 'Cadastro', value: "produtos", 'tooltiptext': 'Selecione esta opção para habilitar a função de cadastro de produtos.', dependsOn: ['empresa']
      }
    ]
  },
  {
    "title": "Operação de entrada",
    "sub_modulos": [
      {
        sub_modulo: 'Pedido de compra', value: "pedido-compra", tooltiptext: 'Selecione esta opção para que seja habilitado o pedido de compra e cadastro de fornecedor e produto.', dependsOn: ['empresa']
      },
      { sub_modulo: 'Validador de ICMS', value: 'validador-compra', tooltiptext: 'Selecione esta opção para que seja habilitada a validação de cálculos das NFE.', dependsOn: ['empresa'] },
      { sub_modulo: 'Guia de recolhimento', value: 'guias-compra', tooltiptext: 'Selecione esta opção para que sejam habilitadas as guias de recolhimento na operação de entrada.', dependsOn: ['empresa', 'validador-compra'] }
    ]
  },
  {
    "title": "Operação de venda",
    "sub_modulos": [
      { sub_modulo: 'Pedido de venda', value: "pedido-saida", tooltiptext: 'Selecione esta opção para que seja habilitado o pedido de saida e cadastro de fornecedor.', dependsOn: ['empresa'] },
      { sub_modulo: 'Validador de ICMS', value: 'validador-saida', tooltiptext: 'Selecione esta opção para que seja habilitada a validação de cálculos  das NFE.', dependsOn: ['empresa'] },
      { sub_modulo: 'Guia de recolhimento (GNRE)', value: 'guias-saida', tooltiptext: 'Selecione esta opção para que sejam habilitadas as guias de recolhimento para operação de saídas.', dependsOn: ['empresa', 'validador-saida'] }
    ]
  },
  {
    "title": "Outros Módulos e Funcionalidades",
    "sub_modulos": [
      { sub_modulo: 'Simulador rápido', value: "simulador_novo", tooltiptext: 'Habilitar o Simulador Rápido.' },
      { sub_modulo: 'Questor', value: "questor", tooltiptext: 'Habilitar captura do questor.', dependsOn: ['empresa', 'captura-xml']},
      { sub_modulo: 'Captura de XML', value: 'captura-xml', tooltiptext: "Habilitar a captura de XML por meio de certificado digital A1.", dependsOn: ['empresa'] },
      { sub_modulo: 'Consultas', value: 'consultas-mva', tooltiptext: 'Habilitar o módulo de consultas de MVA.' },
      { sub_modulo: 'Api', value: 'api', tooltiptext: 'Habilitar a integração via API.' },
      { sub_modulo: 'Tiny', value: 'tiny', tooltiptext: "Habilitar a integração de captura de Notas Fiscais via Token do Tiny.", dependsOn: ['empresa', 'captura-xml'] },
      { sub_modulo: 'SIEG', value: 'sieg', tooltiptext: 'Habilitar a integração de captura de Notas Fiscais via Token da Sieg.', dependsOn: ['empresa', 'captura-xml'] },
      { sub_modulo: 'Notalize', value: 'notalize', tooltiptext: "Habilitar a integração de captura de Notas Fiscais via CNPJ e Password da Notalize.", dependsOn: ['empresa', 'captura-xml'] },
      { sub_modulo: 'Pagamentos', value: 'pagamentos', tooltiptext: 'Habilitar o módulo de pagamentos bancários de guias via integração e geração de arquivos de remessa (VAN Bancária CNAB).' }
    ]
  },
  {
    "title": "Legado",
    "sub_modulos": [{ sub_modulo: 'Simulador de ICMS-ST', value: "pedido-compra", tooltiptext: "Habilitar o Simulador de ICMS-ST - Operação de entrada." }]
  }
]

export function hasAccessToModule(module) {
  if (getUserData()) {
    const userModules = getUserData().user.automacao_modulos
    return {
      // eslint-disable-next-line no-unneeded-ternary
      menu: userModules.includes(module) ? false : true,
      geral: userModules.includes(module)
    }
  }

  return {
    menu: true,
    geral: false
  }


}


export async function getDataCnpj(cnpj) {
  if (!cnpj) return ''
  const cnpjWithoutMask = cnpj.replace(/[^\d]+/g, '')
  const response = await axios.get(`https://ws.hubdodesenvolvedor.com.br/v2/cnpj/?cnpj=${cnpjWithoutMask}&token=116528960aQMgkRKfjF210389504`)
  return response.data.result
}


export function getSimuladorRapido() {
  if (!isUserLoggedIn()) return false
  return getTipoPlano() === 'SIMULADOR-RAPIDO'
}


export const ICMS_DESON_ESTADOS = ['am', 'ac', 'ro', 'rr', 'ap']

export const estadosWithApuracao = ['sp', 'sc', 'mg', 'df', 'go', 'ba', 'mt', 'to', 'rj']

export const estadosWithBaseUnica = ["ac", "df", "ma", "sp", "ba", "ap", "ce", "ro", "to", "rj", 'rs', 'pa', 'pi', 'am', 'ms']

export const estadosWithBaseDupla = ["ac", "df", "ma", "ba", "ap", "sp", "ce", "ro", "to", "rj", 'rs', 'pa', 'pi', 'am']

export const estadosWithCreditoNFe = ["mt", "mg", "pr", "pe", 'sp', 'ap', 'ro']

export const estadosZerarIPI = ["sp", "mg", "pr", "ba", 'pb', 'pi']

export function handleGetQueryParams(key, href) {
  const url = new URL(href || window.location.href).searchParams
  return url.get(key) ? url.get(key) : ''

}
export function handleDeleteQueryParams(key, href) {
  const url = new URL(href || window.location.href)
  url.searchParams.delete(key)
  return url
}


export function handleGetPermissions(permission) {
  const has = getUserData().user.permissions.map((item) => item.name).includes(permission)
  return has
}


export function cleanCNPJ(value) {
  if (value === '' || value === null) return ''

  return value.toString().replace(/\D/g, '')
}